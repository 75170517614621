import React from 'react';
import PropTypes from 'prop-types';

const ConfigModal = ({ isOpen, onClose, onSave, events, currentEventId, continuousListening, handleContinuousListening}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
                <h2 className="text-xl font-bold mb-4">Configuration</h2>
                <div className="mb-4">
                    <label className="block text-gray-800 p-2">Select Event</label>
                    <select
                        className="w-full px-3 py-2 border rounded"
                        value={currentEventId}
                        onChange={(e) => onSave(e.target.value)}
                    >
                        <option value="" disabled>Select an event</option>
                        {events.map(event => (
                            <option key={event._id} value={event._id}>
                                {event.eventName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4 flex items-center">
                    <label htmlFor="show-completed" className="text-sm font-medium mr-2">Continuous Listening:</label>
                    <input
                        id="show-completed"
                        type="checkbox"
                        checked={continuousListening}
                        onChange={handleContinuousListening}
                        className="mr-2"
                        disabled
                    />
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

ConfigModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    events: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        eventName: PropTypes.string.isRequired,
    })).isRequired,
    currentEventId: PropTypes.string.isRequired,
};

export default ConfigModal;