const htmlEncode = (text) => {
    const entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '*': ''
    };
    return String(text).replace(/[&<>"'/]/g, (match) => entityMap[match])
};

const idleResponses = () => {
    const responses = [
        "Hi, I'm Meg. Let's talk tech!",
        "Welcome to CDW! How can I help you today?",
        "Hello there! Interested in learning more about our solutions?",
        "Hi! Feel free to ask me anything about CDW.",
        "Hey! Ready to explore the latest IT innovations?",
        "Welcome! I'm here to assist you with your technology needs.",
        "Greetings! Looking for ways to optimize your IT infrastructure?",
        "Hi there! Let's chat about how CDW can empower your business.",
        "Hello! Curious about our cloud solutions? I can help!",
        "Welcome to the CDW booth! What brings you here today?"
    ];
    return responses[Math.floor(Math.random() * responses.length)]
};



const getQuickReply = () => {
    var quickReplies = ['Let me take a look.', 'Let me check.', 'One moment, please.']
    return quickReplies[Math.floor(Math.random() * quickReplies.length)]
};

const generateSSML = (text, voice, endingSilenceMs) => {
    let ssml = `<speak version='1.0' xmlns='http://www.w3.org/2001/10/synthesis' xmlns:mstts='http://www.w3.org/2001/mstts' xml:lang='en-US'>
    <voice name='${voice}'><mstts:leadingsilence-exact value='0'/><mstts:express-as style="chat"><prosody rate="+1%">${htmlEncode(text)}</prosody>
    </mstts:express-as>${endingSilenceMs > 0 ? `<break time='${endingSilenceMs}ms'/>` : ''}</voice></speak>`;
    return ssml;
};


export { htmlEncode, getQuickReply, generateSSML, idleResponses };