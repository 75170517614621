import React, { useState, useEffect, useMemo } from 'react';
import {
    FaMicrophone,
    FaCirclePause,
    FaCirclePlay,
    FaRotate,
    FaCircleStop,
} from "react-icons/fa6";
import ControlButton from "./controls/ControlButton";

const Controls = ({ handleStartAvatarButton, handleStartRecognitionButton, handleStopSpeakingButton, handleStopRecognitionButton, handleResetLangchain, avatarSpeaking, avatarStarted, isListening, langchainSocketReady, avatarLoading }) => {
    const [recognitionReady, setRecognitionReady] = useState(false);
    const [pauseRecognition, setPauseRecognition] = useState(false);
    const [startButtonEnabled, setStartButtonEnabled] = useState(false);

    useEffect(() => {
        setRecognitionReady(!avatarSpeaking && avatarStarted && langchainSocketReady);
    }, [avatarSpeaking, avatarStarted, langchainSocketReady]);

    useEffect(() => {
        setPauseRecognition(isListening && avatarStarted);
    }, [avatarStarted, isListening]);

    useEffect(() => {
        setStartButtonEnabled(langchainSocketReady && !avatarStarted);
    }, [avatarStarted, langchainSocketReady]);


    const buttons = useMemo(() => [
        { icon: <FaCirclePlay />, key: 'start', action: handleStartAvatarButton, tooltip: 'Start Avatar', disabled: !startButtonEnabled, glow: true, loading: avatarLoading },
        { icon: <FaMicrophone />, key: 'microphone', action: handleStartRecognitionButton, tooltip: 'Start Recognition', disabled: !recognitionReady, glow: true, loading: false },
        { icon: <FaCircleStop />, key: 'stop', action: handleStopSpeakingButton, tooltip: 'Stop Speaking', disabled: !avatarSpeaking, glow: true, loading: false },
        { icon: <FaCirclePause />, key: 'pause', action: handleStopRecognitionButton, tooltip: 'Pause Recognition', disabled: !pauseRecognition, glow: false, loading: false },
        { icon: <FaRotate />, key: 'reload', action: handleResetLangchain, tooltip: 'New Agent Session', disabled: false, glow: false, loading: false },
    ], [startButtonEnabled, recognitionReady, avatarSpeaking, pauseRecognition, handleStartAvatarButton, handleStartRecognitionButton, handleStopSpeakingButton, handleStopRecognitionButton, handleResetLangchain, avatarLoading]);

    return (
        <div className="absolute top-1/3 left-2 flex items-center justify-center">
            <ul className="flex flex-col items-center">
                {buttons.map(button => (
                    <li key={button.key} className="p-1.5">
                        <ControlButton
                            icon={button.icon}
                            action={button.action}
                            tooltip={button.tooltip}
                            disabled={button.disabled}
                            glow={button.glow}
                            loading={button.loading} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Controls;